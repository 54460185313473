var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.classes, { 'is-kvi': _vm.showKviLabel }],attrs:{"data-preload":_vm.$options.name}},[(_vm.showKviLabel)?_c('div',{staticClass:"price-label"},[_vm._v("\n    "+_vm._s(_vm.$t('price.kvi'))+"\n  ")]):_vm._e(),_vm._v(" "),(!!_vm.discountConfig && _vm.promotionLabelActive)?_c('div',{class:[
      'price-label',
      {
        'price-label--regular-discount':
          _vm.discountConfig.promotionLabelType === 'regular_campaign' || false,
        'price-label--regular-discount-rebranding':
          (_vm.discountConfig.promotionLabelType === 'regular_campaign' &&
            _vm.isRebrandingChannel) ||
          false,
        'price-label--black-friday':
          _vm.discountConfig.promotionLabelType === 'black_friday' || false,
      },
    ]},[_vm._v("\n    "+_vm._s(_vm.discountConfig.promotionLabelText)+"\n  ")]):_vm._e(),_vm._v(" "),_c('span',{class:_vm.label},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('span',{staticClass:"price-value"},[_vm._v(_vm._s(_vm.value))]),_vm._v(" "),(_vm.isBusinessCustomer)?_c('span',{staticClass:"price__value-type",class:`price-business-customer__${_vm.isNet ? 'netto' : 'gross'}`},[_vm._v("\n    "+_vm._s(_vm.isNet ? _vm.$t('price.business-net') : '')+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }