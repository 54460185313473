<i18n src="./translations"></i18n>

<template>
  <div
    :class="[classes, { 'is-kvi': showKviLabel }]"
    :data-preload="$options.name"
  >
    <div v-if="showKviLabel" class="price-label">
      {{ $t('price.kvi') }}
    </div>

    <div
      v-if="!!discountConfig && promotionLabelActive"
      :class="[
        'price-label',
        {
          'price-label--regular-discount':
            discountConfig.promotionLabelType === 'regular_campaign' || false,
          'price-label--regular-discount-rebranding':
            (discountConfig.promotionLabelType === 'regular_campaign' &&
              isRebrandingChannel) ||
            false,
          'price-label--black-friday':
            discountConfig.promotionLabelType === 'black_friday' || false,
        },
      ]"
    >
      {{ discountConfig.promotionLabelText }}
    </div>

    <span :class="label">{{ label }}</span>

    <span class="price-value">{{ value }}</span>

    <span
      v-if="isBusinessCustomer"
      class="price__value-type"
      :class="`price-business-customer__${isNet ? 'netto' : 'gross'}`"
    >
      {{ isNet ? $t('price.business-net') : '' }}
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'Price',
  mixins: [globalMixin],
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    isNet: {
      type: Boolean,
      default: false,
    },
    isKvi: {
      type: Boolean,
      default: false,
    },
    discountConfig: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('core', ['isBusinessCustomer', 'isRebrandingChannel']),
    ...mapState('renderingSalesChannelConfig', {
      isKviLabelEnabled: ({ isKviLabelEnabled }) => isKviLabelEnabled,
    }),
    ...mapState('core', {
      promotionLabelActive: ({ salesChannel }) =>
        salesChannel?.config?.promotionLabelActive || false,
    }),
    showKviLabel() {
      return (
        this.isKvi &&
        this.isKviLabelEnabled &&
        (!this.discountConfig || !this.promotionLabelActive)
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.price {
  display: block;
  white-space: nowrap;
  // text-align: right;

  color: var(--color-mine-shaft);

  &--inline {
    display: inline-block;
    color: inherit;
  }

  &--bold {
    font-weight: bold;
  }

  &--big {
    text-align: left;
    font-weight: bold;
    font-size: 40px;
    color: var(--color-chathamsblue);
  }

  &--com {
    color: var(--color-primary);
  }

  &--highlighted {
    color: var(--color-chathamsblue);
  }

  &--outdated {
    color: var(--color-chathamsblue);
    text-decoration: line-through;
  }

  &.is-kvi .price-value {
    color: var(--color-primary);
  }

  .price-label {
    width: fit-content;
    position: relative;
    padding-left: var(--space-1);
    margin-bottom: var(--space-0--half);
    font-size: var(--font-size-S);
    line-height: var(--font-size-M);
    color: var(--color-white);
    background-color: var(--color-primary);
    height: 22px; // Design team recommended size.
    display: flex;
    align-items: center;

    &::after {
      content: '';
      border-style: solid;
      border-width: 11px 7px; // Design team recommended size.
      border-color: var(--color-primary);
      border-right-color: white !important;
    }

    &--regular-discount {
      background-color: var(--color-red-discount);

      &::after {
        border-color: var(--color-red-discount) !important;
        border-right-color: white !important;
      }
    }

    &--regular-discount-rebranding {
      background-color: var(--color-orange);

      &::after {
        border-color: var(--color-orange) !important;
        border-right-color: white !important;
      }
    }

    &--black-friday {
      background-color: var(--color-black);

      &::after {
        border-color: var(--color-black) !important;
        border-right-color: white !important;
      }
    }
  }
}

.price__value-type {
  font-size: var(--font-size-M);
  font-weight: normal;
}
</style>
