var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.classes, { 'product-tile--readonly': _vm.readonly }],attrs:{"data-preload":[_vm.$options.name]}},[_c('div',{staticClass:"contentContainer"},[_c('div',{staticClass:"content"},[(_vm.readonly)?_c('div',{staticClass:"product-tile__image-container"},[(_vm.product.image)?_c('AdvancedImage',{staticClass:"product-tile__product-image",attrs:{"src":_vm.product.image,"alt":_vm.product.image.alt || '',"config":{ q: 80, w: 86 }}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text"},[_c('div',{class:['brand', 'qa-ProductTile__brand']},[_c('MaskedLink',{staticClass:"qa-ProductTile__tracking",attrs:{"redirect-to":_vm.product.productUrl,"text":_vm.product.brand}})],1),_vm._v(" "),_c('div',{staticClass:"title qa-ProductTile__title"},[_c('MaskedLink',{staticClass:"qa-ProductTile__tracking",attrs:{"redirect-to":_vm.product.productUrl,"text":_vm.product.name}})],1),_vm._v(" "),_c('div',{staticClass:"article"},[_vm._v("\n          "+_vm._s(_vm.$t('product-tile.sku'))+" "+_vm._s(_vm.product.displaySku)+"\n        ")]),_vm._v(" "),(!_vm.readonly)?_c('div',{staticClass:"availability"},[_c('Availability',{staticClass:"qa-ProductTile__availabilty",attrs:{"availability-key":_vm.availabilityKey,"is-new-availability":_vm.isNewAvailability,"label":_vm.product && _vm.isNewAvailability
                ? ''
                : _vm.product.availability && _vm.product.availability.label}})],1):_vm._e()])]),_vm._v(" "),(!_vm.readonly)?_c('div',{staticClass:"numbers"},[_c('div',{staticClass:"quantity qa-ProductTile__quantity"},[(!_vm.fixedQuantity)?_c('QuantitySelector',{attrs:{"label":_vm.$t('product-tile.quantity'),"value":_vm.product.quantityAmount,"disabled":_vm.readonly,"product":_vm.product,"choices":_vm.product.quantityOptions,"threshold":_vm.product.quantityThreshold,"is-minicart":""},on:{"onQuantityChange":function($event){return _vm.handleQuantityChange($event)}}},[_c('div',{staticClass:"total"},[(_vm.product.isDiscountCodeApplied)?[_vm._l((_vm.selectedDiscountPrices),function(price,key){return [(
                    price.price >= 0 || (price.price >= 0 && price.discounted)
                  )?_c('Price',{key:key,staticClass:"price",class:{
                    'price--bold price--large': key === 0,
                    'discount--color': key === 0 && _vm.uvpLegalSafeActive,
                    'price--strikethrough':
                      key === 1 && !_vm.isBusinessPlusCustomer,
                    'hide-strikethrough':
                      key === 1 &&
                      !_vm.isBusinessPlusCustomer &&
                      _vm.uvpLegalSafeActive,
                  },attrs:{"label":_vm.removeNetLabel(_vm.selectedDiscountPrices[key].label),"value":_vm.normalizePrice(price.price),"is-kvi":_vm.product.isKvi,"discount-config":key === 0 ? _vm.discountConfig : null,"data-testid":key === 1 && !_vm.isBusinessPlusCustomer
                      ? 'discounted-price'
                      : undefined}}):_vm._e()]})]:_vm._l((_vm.selectedPrices),function(price,key){return _c('Price',{key:key,staticClass:"price",class:{ 'price--bold price--large': key === 0 },attrs:{"label":_vm.removeNetLabel(_vm.selectedPrices[key].label),"value":_vm.normalizePrice(price.price),"is-kvi":_vm.product.isKvi}})})],2)]):_c('div',{staticClass:"fixedQuantity"},[_vm._v("\n          "+_vm._s(_vm.$t('product-tile.quantity'))+":\n          "+_vm._s(_vm.product.quantityAmount)+"\n        ")])],1)]):_vm._e()]),_vm._v(" "),_c('TileActions',{attrs:{"actions-list":_vm.actions,"delete-product":_vm.deleteProduct,"product-id":_vm.product.productId},on:{"action":({ type }) => _vm.$emit('action', { type, product: _vm.product })}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }